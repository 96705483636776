import { BirthdayPicker, Button, Card, Checkbox, Choice, Colors, Form, Icon, Icons, Link, Logo, PhoneInputField, SingleSelect, StyledHeading, StyledParagraph, TextField, View, useAuthState, useForm } from "@barscience/global-components";
import StandardPageWrapper from "../components/layout/StandardPageWrapper";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";

const CREATE_USER = gql`
mutation createUserFromInvite($token: String!, $input: CreateUserFromInviteInput!) {
  createUserFromInvite(token: $token, input: $input) {
    id
  }
}
`;

type CreateUserResponse = {
  createUserFromInvite: {
    id: string;
  } | null;
}

type CreateAccountInput = {
  password: string;
  confirmPassword: string;
  phone: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  dateOfBirth: string;
  termsAccepted: boolean;
}

const states = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

export default function SignUp() {
  const navigate = useNavigate();
  const { state } = useAuthState();
  const [searchParams] = useSearchParams();
  const [createUser, { data: createUserData, loading: createUserIsLoading, error: createUserError }] = useMutation<CreateUserResponse>(CREATE_USER);

  const validateBirthdate = (name: string, value: string) => {
    if (value === '' || value.length !== 10) {
      return null;
    }

    // Make sure the user is at least 13 years old
    const birthDate = new Date(value);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    if (age < 13) {
      return 'You must be at least 13 years old to sign up.';
    }

    return null;
  }

  const handleSignup = async (values: CreateAccountInput) => {
    await createUser({
      variables: {
        token: searchParams.get('token') || '',
        input: {
          password: values.password,
          phone: values.phone,
          address: {
            street: values.street,
            city: values.city,
            state: values.state,
            zip: values.zip,
          },
          dateOfBirth: values.dateOfBirth,
        },
      },
    });
  }

  const signupForm = useForm<CreateAccountInput>({
    initialValues: {
      password: '',
      confirmPassword: '',
      phone: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      dateOfBirth: '',
      termsAccepted: false,
    },
    onSubmit: handleSignup,
  });

  if (state.user) {
    navigate('/');
  }

  if (!searchParams.has('token') || createUserError?.graphQLErrors[0]?.message.includes('invite link is invalid')) {
    return (
      <StandardPageWrapper>
        <View style={{ alignItems: 'center', gap: '32px', height: '100%', justifyContent: 'space-between', maxWidth: 'min(600px, 100%)' }}>
          <View>
            <Logo />
          </View>
          <View style={{ gap: '16px' }}>
            <StyledHeading tag='h4' style={{ textAlign: 'center' }}>Oops! That invite link is invalid.</StyledHeading>
            <StyledParagraph style={{ textAlign: 'center' }}>
              The invite link you've used is invalid. You can <Link href='https://barscience.us/signup'>request a new one here</Link>. Please note that invite links expire after 24 hours.
            </StyledParagraph>
          </View>
          <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
            <Icon size='medium' icon={Icons.Copyright} />
            <StyledParagraph>
              Copyright 2023 Bar Science
            </StyledParagraph>
          </View>
        </View>
      </StandardPageWrapper>
    );
  }

  if (createUserData?.createUserFromInvite?.id) {
    window.location.reload();
  }

  return (
    <>
      <StandardPageWrapper>
        <View style={{ alignItems: 'center', gap: '32px' }}>
          <Card size='medium' style={{ maxWidth: 'min(500px, 100%)', minHeight: 'fit-content', padding: '32px' }}>
            <Form handleSubmit={signupForm.handleSubmit}>
              <View style={{ gap: '48px' }}>
                <View style={{ gap: '8px' }}>
                  <StyledHeading tag='h4' weight='bold'>Create your account</StyledHeading>
                </View>

                <View style={{ gap: '16px' }}>
                  <TextField name='password' label='Password' type='password' value={signupForm.values.password} error={signupForm.errors.password} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} validate={validateNewPassword} required />
                  <View style={{ gap: '4px' }}>
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                      {hasAtLeastEightCharacters(signupForm.values.password) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} key='yes' /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} key='no' />}
                      <StyledParagraph style={hasAtLeastEightCharacters(signupForm.values.password) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain at least 8 characters</StyledParagraph>
                    </View>

                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                      {hasLowercaseLetter(signupForm.values.password) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} key='yes' /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} key='no' />}
                      <StyledParagraph style={hasLowercaseLetter(signupForm.values.password) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain a lowercase letter</StyledParagraph>
                    </View>

                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                      {hasUppercaseLetter(signupForm.values.password) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} key='yes' /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} key='no' />}
                      <StyledParagraph style={hasUppercaseLetter(signupForm.values.password) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain an uppercase letter</StyledParagraph>
                    </View>

                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                      {hasSpecialCharacter(signupForm.values.password) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} key='yes' /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} key='no' />}
                      <StyledParagraph style={hasSpecialCharacter(signupForm.values.password) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Must contain a special symbol</StyledParagraph>
                    </View>

                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                      {passwordsMatch(signupForm.values.password, signupForm.values.confirmPassword) ? <Icon icon={Icons.CircleCheckmark} size='medium' style={{ color: Colors.primary500 }} key='yes' /> : <Icon icon={Icons.CircleX} size='medium' style={{ color: Colors.error500 }} key='no' />}
                      <StyledParagraph style={passwordsMatch(signupForm.values.password, signupForm.values.confirmPassword) ? { color: Colors.primary500 } : { color: Colors.error500 }}>Passwords must match</StyledParagraph>
                    </View>
                  </View>

                  <TextField name='confirmPassword' label='Confirm Password' type='password' value={signupForm.values.confirmPassword} error={signupForm.errors.confirmPassword} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} validate={validateNewPassword} required />
                </View>


                <PhoneInputField name='phone' label='Phone' value={signupForm.values.phone} error={signupForm.errors.phone} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required />

                <View style={{ gap: '16px' }}>
                  <TextField label='Street Address' name='street' value={signupForm.values.street} error={signupForm.errors.street} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required />

                  <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                    <TextField label='City' name='city' value={signupForm.values.city} error={signupForm.errors.city} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required />

                    <SingleSelect label='State' name='state' value={signupForm.values.state} error={signupForm.errors.state} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required>
                      {states.map((state, index) => (
                        <Choice label={state} value={state} key={index} />
                      ))}
                    </SingleSelect>

                  </View>
                  <TextField label='Zipcode' name='zip' value={signupForm.values.zip} error={signupForm.errors.zip} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} required style={{ width: '50%' }} />
                </View>

                <BirthdayPicker name='dateOfBirth' label='Date of Birth' value={signupForm.values.dateOfBirth} error={signupForm.errors.dateOfBirth} onChange={signupForm.handleChange} onValidate={signupForm.handleValidate} validate={validateBirthdate} required />

                <View style={{ flexDirection: 'row', gap: '8px' }}>
                  <Checkbox label='' name='termsAccepted' checked={signupForm.values.termsAccepted} onChange={signupForm.handleChange} />
                  <StyledParagraph>I agree to the <Link href='https://barscience.us/about/tos'>Terms of Service</Link> and <Link href='https://barscience.us/about/privacy-policy'>Privacy Policy</Link>.</StyledParagraph>
                </View>

                {createUserError && <StyledParagraph style={{ color: Colors.error500 }}>{createUserError.message}</StyledParagraph>}
                <Button label='Sign Up' variant='primary' role='button' action={() => { }} type='submit' isFullWidth disabled={signupForm.hasError || !signupForm.values.termsAccepted} loading={createUserIsLoading} style={{ marginTop: '16px' }} />
              </View>
            </Form>
          </Card>
          <View style={{ flexDirection: 'row', gap: '8px', color: Colors.neutral700 }}>
            <Icon size='medium' icon={Icons.Copyright} />
            <StyledParagraph>
              Copyright 2023 Bar Science
            </StyledParagraph>
          </View>
        </View>
      </StandardPageWrapper>
    </>
  );
}

const hasUppercaseLetter = (value: string) => {
  if (value.toLowerCase() === value) {
    return false;
  }
  return true;
}

const hasLowercaseLetter = (value: string) => {
  if (value.toUpperCase() === value) {
    return false;
  }
  return true;
}

const hasSpecialCharacter = (value: string) => {
  const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
  return specialCharacters.test(value);
}

const hasAtLeastEightCharacters = (value: string) => {
  if (value.length < 8) {
    return false;
  }
  return true;
}

const passwordsMatch = (pw1: string, pw2: string) => {
  return pw1 === pw2;
}

const validateNewPassword = (name: string, value: string) => {
  if (!hasAtLeastEightCharacters(value) || !hasUppercaseLetter(value) || !hasLowercaseLetter(value) || !hasSpecialCharacter(value)) {
    return '';
  }
  return null;
}