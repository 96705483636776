import { AtomSpinner, Button, Card, Choice, Colors, ErrorPage, Icon, Icons, InfoPanel, OrgRoles, Products, SingleSelect, StandardAlert, StyledHeading, StyledParagraph, TextField, View, generateId, getProductUrl, useAlertState, useAuthState, useForm } from "@barscience/global-components";
import StandardPageWrapper from "../components/layout/StandardPageWrapper";
import { gql, useMutation, useQuery } from "@apollo/client";
import { CSSProperties, StyleSheet, css } from "aphrodite";
import { useState } from "react";
import { Link } from "react-router-dom";

/* Get User Products Query */
const GET_USER_PRODUCTS = gql`
query getCurrentUserProducts {
  currentUser {
    id
    firstName
    org {
      id
      name
      plans {
        plan {
          product {
            id
            name
          }
        }
      }
    }
    productRoles {
      id
      product {
        id
      }
    }
  }
}
`;

type GetUserProductsResponse = {
  currentUser: {
    id: string;
    firstName: string;
    org: {
      id: string;
      name: string;
      plans: Plan[];
    } | null;
    productRoles: ProductRole[];
  } | null;
}

type Plan = {
  plan: {
    product: {
      id: string;
      name: string;
    };
  };
}

type ProductRole = {
  id: string;
  product: {
    id: string;
  };
}

/* Join Org Mutation */
const JOIN_ORG = gql`
mutation joinOrgByCode($orgJoinCode: String!) {
  success: joinOrg(orgJoinCode: $orgJoinCode)
}
`;

type JoinOrgResponse = {
  success: boolean;
}

type JoinOrgInput = {
  orgJoinCode: string;
}

/* Create Org Mutation */
const CREATE_ORG = gql`
mutation createOrganization($input: CreateOrgInput!) {
  id: createOrg(input: $input)
}
`;

type CreateOrgResponse = {
  id: string | null;
}

type CreateOrgInput = {
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

/* Page State */
enum Role {
  NONE = 'NONE',
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
}

const states = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

const styles = StyleSheet.create({
  productCard: {
    color: 'inherit',
    flexGrow: 1,
    maxWidth: 'min(100%, 400px)',
    minWidth: 'min(200px, 100%)',
    textDecoration: 'none',
  },
});

const productCardStyle: CSSProperties = {
  height: '100%',
  ':hover': {
    backgroundColor: Colors.neutral100,
  },
  ':active': {
    backgroundColor: Colors.neutral200,
  },
}

const roleCardStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  width: '50%',
  '@media (max-width: 767px)': {
    width: '100%'
  },
  ':hover': {
    backgroundColor: Colors.neutral100,
  },
  ':active': {
    backgroundColor: Colors.neutral200,
  },
}

export default function Home() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [role, setRole] = useState<Role>(Role.NONE);
  const { data, loading, error } = useQuery<GetUserProductsResponse>(GET_USER_PRODUCTS);
  const [joinOrg, { loading: joinOrgIsLoading }] = useMutation<JoinOrgResponse>(JOIN_ORG);
  const [createOrg, { loading: createOrgIsLoading }] = useMutation<CreateOrgResponse>(CREATE_ORG);

  const handleJoinOrg = async (values: JoinOrgInput) => {
    const { data, errors } = await joinOrg({
      variables: {
        orgJoinCode: values.orgJoinCode,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Error joining organization' description={errors[0].message} type='error' />
      addAlert(id, alert);
    }

    if (data?.success) {
      window.location.reload();
    }
  }

  const joinOrgForm = useForm<JoinOrgInput>({
    initialValues: {
      orgJoinCode: '',
    },
    onSubmit: handleJoinOrg,
  });

  const handleCreateOrg = async (values: CreateOrgInput) => {
    const { errors } = await createOrg({
      variables: {
        input: {
          name: values.name,
          address: {
            street: values.street,
            city: values.city,
            state: values.state,
            zip: values.zip,
          },
        },
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert id={id} title='Error creating company' description={errors[0].message} type='error' />
      addAlert(id, alert, 30);
    } else {
      window.location.replace(getProductUrl(Products.Org) + '/subscriptions');
    }
  }

  const createOrgForm = useForm<CreateOrgInput>({
    initialValues: {
      name: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    onSubmit: handleCreateOrg,
  });

  const selectRolePage = (
    <View style={{ gap: '32px' }}>
      <View style={{ alignItems: 'center', gap: '32px', justifyContent: 'center' }}>
        <StyledHeading tag='h3'>What is your role?</StyledHeading>

        <View style={{ alignItems: 'center', gap: '16px', width: '100%' }}>
          <View onClick={() => { setRole(Role.EMPLOYEE); }} style={roleCardStyle}>
            <Card size='medium' style={{ background: 'none', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <StyledHeading tag='h6'>Employee</StyledHeading>
            </Card>
          </View>

          <View onClick={() => { setRole(Role.OWNER); }} style={roleCardStyle}>
            <Card size='medium' style={{ background: 'none', boxSizing: 'border-box', display: 'flex', justifyContent: 'center', width: '100%' }}>
              <StyledHeading tag='h6'>Business Owner</StyledHeading>
            </Card>
          </View>
        </View>
      </View>
    </View>
  );

  const joinOrgPage = (
    <View style={{ alignItems: 'center', gap: '32px', justifyContent: 'center' }}>
      <StyledHeading tag='h3'>Join your organization</StyledHeading>
      <Card size='medium'>
        <View style={{ gap: '16px' }}>
          <TextField label={'Enter your org\'s join code'} name='orgJoinCode' description={'You can obtain this from your org\'s manager or owner'} value={joinOrgForm.values.orgJoinCode} error={joinOrgForm.errors.orgJoinCode} onChange={joinOrgForm.handleChange} onValidate={joinOrgForm.handleValidate} required />
          <Button label='Join' variant='primary' role='button' action={() => { handleJoinOrg(joinOrgForm.values); }} disabled={joinOrgForm.hasError} loading={joinOrgIsLoading} isFullWidth />
        </View>
      </Card>
    </View>
  );

  const createOrgPage = (
    <View style={{ alignItems: 'center', gap: '32px', justifyContent: 'center', width: '100%' }}>
      <StyledHeading tag='h3'>Create your company</StyledHeading>
      <Card size='medium' style={{ width: 'min(100%, 500px)' }} >
        <View style={{ gap: '16px', width: '100%' }}>
          <TextField label='Company Name' name='name' value={createOrgForm.values.name} error={createOrgForm.errors.name} onChange={createOrgForm.handleChange} onValidate={createOrgForm.handleValidate} required />
          <TextField label='Street Address' name='street' value={createOrgForm.values.street} error={createOrgForm.errors.street} onChange={createOrgForm.handleChange} onValidate={createOrgForm.handleValidate} required />
          <TextField label='City' name='city' value={createOrgForm.values.city} error={createOrgForm.errors.city} onChange={createOrgForm.handleChange} onValidate={createOrgForm.handleValidate} required />
          <SingleSelect label='State' name='state' value={createOrgForm.values.state} error={createOrgForm.errors.state} onChange={createOrgForm.handleChange} onValidate={createOrgForm.handleValidate} required >
            {states.map(state => <Choice label={state} value={state} />)}
          </SingleSelect>
          <TextField label='Zip Code' name='zip' value={createOrgForm.values.zip} error={createOrgForm.errors.zip} onChange={createOrgForm.handleChange} onValidate={createOrgForm.handleValidate} required />
          <Button label='Create Your Company' variant='primary' role='button' action={() => { handleCreateOrg(createOrgForm.values); }} disabled={createOrgForm.hasError} loading={createOrgIsLoading} isFullWidth />
        </View>
      </Card>
    </View>
  );

  const getPage = () => {
    switch (role) {
      case Role.NONE:
        return selectRolePage;
      case Role.EMPLOYEE:
        return joinOrgPage;
      case Role.OWNER:
        return createOrgPage;
      default:
        return null;
    }
  }

  const productsWithAccess = data?.currentUser?.org?.plans.filter(plan => data.currentUser?.productRoles.some(role => role.product.id === plan.plan.product.id)) || [];

  if (error) {
    return (
      <StandardPageWrapper style={{ padding: '40px' }}>
        <ErrorPage />
      </StandardPageWrapper>
    );
  }

  if (state.user?.roles[Products.Org] === OrgRoles.Owner && productsWithAccess.length === 1) {
    window.location.replace(getProductUrl(Products.Org) + '/subscriptions');
  }

  return (
    <StandardPageWrapper style={{ padding: '40px' }}>
      <View style={{ height: '100%', maxWidth: 'min(800px, 100%)', minWidth: 'min(800px, 100%)' }}>
        {loading ?
          <AtomSpinner size='large' />
          :
          <>
            {data?.currentUser?.org ?
              <>
                <StyledHeading tag='h3'>Your Products</StyledHeading>
                {productsWithAccess.length > 0 ?
                  <View style={{ gap: '32px' }}>
                    <View style={{ alignItems: 'stretch', flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'flex-start', marginTop: '24px', width: '100%' }}>
                      {productsWithAccess.map((plan) => (
                        <Link to={getProductUrl(plan.plan.product.id)} target='_self' className={css(styles.productCard)}>
                          <Card key={plan.plan.product.id} style={productCardStyle}>
                            <View style={{ flexDirection: 'row', gap: '8px' }}>
                              {getProductIcon(plan.plan.product.id)}
                              <StyledHeading tag='h6'>{plan.plan.product.name}</StyledHeading>
                            </View>
                          </Card>
                        </Link>
                      ))}
                    </View>
                    {(state.user?.roles[Products.Org] !== OrgRoles.Owner && state.user?.roles[Products.Org] !== OrgRoles.BillingManager && state.user?.roles[Products.Org] !== OrgRoles.Manager) &&
                      <InfoPanel type='info'>
                        <StyledParagraph>Don't see a product you should have access to? Ask your business manager to provide you with access.</StyledParagraph>
                      </InfoPanel>
                    }
                  </View>
                  :
                  <View style={{ alignItems: 'center', gap: '32px', justifyContent: 'center', minHeight: '200px', marginTop: '48px' }}>
                    <img src='/illustrations/empty-folder.svg' alt='An empty folder' style={{ maxWidth: 'min(100%, 600px)' }} />
                    <StyledParagraph style={{ color: Colors.neutral700, fontSize: '20px', textAlign: 'center' }}>You don't have access to any products. Ask your business manager to provide you with access.</StyledParagraph>
                  </View>
                }
              </>
              :
              getPage()
            }
          </>
        }
      </View>
    </StandardPageWrapper>
  );
}

const getProductIcon = (id: string) => {
  switch (id) {
    case 'inventory':
      return <Icon icon={Icons.Inventory} size='medium' />;
    case 'scheduling':
      return <Icon icon={Icons.Schedule} size='medium' />;
    case 'training':
      return <Icon icon={Icons.Training} size='medium' />;
    case 'org':
      return <Icon icon={Icons.Organization} size='medium' />;
    case 'admin':
      return <Icon icon={Icons.Admin} size='medium' />;
    default:
      return null
  }
}