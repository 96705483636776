import { ExternalRedirect, Header, useAlertState, useAuthState, View } from '@barscience/global-components';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import SignUp from './pages/SignUp';
import Home from './pages/Home';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { alerts } = useAlertState();
  const location = useLocation();
  const { state } = useAuthState();

  if (!state.user && !location.pathname.includes('/create-account')) {
    let authUrl = 'https://auth.barscience.us/login';
    if (window.location.href.includes('-dev')) {
      authUrl = 'https://auth-dev.barscience.us/login';
    } else if (!window.location.href.includes('barscience.us')) {
      authUrl = `http://${window.location.hostname}:6002/login`;
    }
    return (
      <ExternalRedirect to={authUrl + '?continue=' + window.location.href} />
    );
  }

  return (
    <>
      {alerts.length > 0 &&
        <View style={{ bottom: '24px', gap: '16px', left: '24px', position: 'fixed', zIndex: 1 }}>
          {alerts.map((a) => {
            return (
              <React.Fragment key={a.id}>
                {a.alert}
              </React.Fragment>
            );
          })}
        </View>
      }
      <View style={{ height: '100vh', overflowX: 'hidden', overflowY: 'auto', width: '100vw'}}>
        <ScrollToTop />
        
        <Header onSidebarClick={() => {}} />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/create-account' element={<SignUp />} />
        </Routes>
      </View>
    </>
  );
}

export default App;